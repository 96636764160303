
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";

export default defineComponent({
name: "CostSalesNew",
components: {
  Form,
  ElCurrencyInputNoSymbol,
},
async mounted() {
  this.init();
  this.initMonths();
},
data() {


  const months = [
    "Jan",
    "Feb",
    "Mac",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const totalMonths = 0;

  const statements = {
    costSales: {
        label: "Cost of Sales",
        child: {
          openingInventory: {
              label: "Opening Inventory",
              value: [],
              total:0,
          },
          materialPurchases: {
              label: "Material Purchases",
              value: [],
              total:0,
          },
          others: {
              label: "Others",
              value: [],
              total:0,
          },
        }
    },
    totalCostSales: {
        label: "Total",
        value: [],
        total:0,
    },
  };

  const closingInventory = {
    label: "Closing Inventory",
    value: [],
    total:0,
  }

  return {
    months,
    totalMonths,
    statements,
    closingInventory,
  };
},
setup() {
  return {};
},
methods: {
  init() {
    setCurrentPageBreadcrumbs("Cost of Sales", ["Financial Statements","Income Statement"]);
  },

  onSubmit(values){
    console.log(values);
    Swal.fire({
      title: 'Congratulation!',
      text: 'Calculation has been saved!',
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    })
  },

  initMonths() {
    for (let index = 0; index < this.months.length; index++) {
        this.statements.totalCostSales.value.push(0);
        this.closingInventory.value.push(0);
    }

    for (let key of Object.keys(this.statements.costSales.child)) {
        let costSalesChild = this.statements.costSales.child;
        for (let index = 0; index < this.months.length; index++) {
            costSalesChild[key].value.push(0);
        }
    }
  },

  calculateTotalCostSales() {
      for (let i = 0; i < this.statements.totalCostSales.value.length; i++) {
        this.statements.totalCostSales.value[i] = 0;
      }

      for (let keyChild of Object.keys(this.statements.costSales.child)) {
        let costSalesChild = this.statements.costSales.child[keyChild];
          let stateChildValue = costSalesChild.value;

          costSalesChild.total = stateChildValue.reduce((sum,item) => sum + item);

          for (let i = 0; i < this.statements.totalCostSales.value.length; i++) {
            this.statements.totalCostSales.value[i] += stateChildValue[i];
          }
      }

      this.closingInventory.total = this.closingInventory.value.reduce((sum,item) => sum + item);

      for (let i = 0; i < this.statements.totalCostSales.value.length; i++) {
          this.statements.totalCostSales.value[i] -= this.closingInventory.value[i];
      }
  },
},
});
