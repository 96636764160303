
import { defineComponent, onMounted } from "vue";
import KTTableWidgetCostSales from "@/components/widgets/tables/WidgetCostSales.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "daily-cash-flow",
  components: {
    KTTableWidgetCostSales,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Cost of Sales", ["Financial Statements","Income Statements"]);
    });
  },
});
